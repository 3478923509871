import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    position: "relative",
    display: "flex",
    width: 550,
    flexShrink: 0,
    flexDirection: "column",
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("lg")]: {
      width: 490,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: 180,
      justifyContent: "center",
    },
  },
  positionContainer: {
    display: "flex",
    width: 550,
    flexShrink: 0,
    flexDirection: "column",
    position: "fixed",
    top: 0,
    height: "calc(100vh - 0px)",
    [theme.breakpoints.down("lg")]: {
      width: 490,
    },
  },
  contentContainer: {
    whiteSpace: "nowrap",
    height: "100%",
    display: "flex",
    position: "relative",
    flexDirection: "column",
  },
  brandContainer: {
    display: "flex",
    width: "100%",
    flex: 0.5,
    justifyContent: "center",
  },
  brandContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  brandIcon: {
    fontSize: 65,
    color: theme.palette.common.white,
  },
  brandText: {
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: 500,
    fontSize: 50,
    color: theme.palette.common.white,
    textTransform: "uppercase",
    marginLeft: theme.spacing(4),
  },
  middleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    flex: 1,
  },
  middleContent: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  absolute: {
    position: "absolute",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    minWidth: "100%",
    minHeight: "100%",
  },
  bottomContentContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    backgroundColor: theme.palette.common.white,
  },
  bottomContent: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    height: 59,
  },
  primaryText: {
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: 500,
    fontSize: 10,
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1.2),
  },
  textContainer: {
    margin: theme.spacing(3, 0),
  },
  brandPrimary: {
    fontSize: 28,
    fontWeight: 500,
    color: theme.palette.common.white,
    fontFamily: "'Quicksand', sans-serif",
  },
  backgroundContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    marginBottom: -3,
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  backgroundContent: {
    display: "flex",
    alignItems: "flex-end",
    width: "100%",
  },
  middleBackgroundImage: {
    maxWidth: "50%",
  },
  lastBackgroundImage: {
    position: "absolute",
    right: 25,
    [theme.breakpoints.down("lg")]: {
      right: 12,
    },
  },
  bottomBrandIcon: {
    fontSize: 18,
    color: theme.palette.primary.main,
  },
}));
