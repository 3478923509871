import { startSubmit, stopSubmit } from "redux-form";
import kebabCase from "lodash/kebabCase";
import find from "lodash/find";
import { addNotification } from "../notification";
import {
  getRiskClasses,
  getCoverTypes,
  getVehicleTypes,
  getVehicleModels,
  getVehicleMakes,
  getTonnageRanges,
  getAdditionalBenefits,
  createQuote,
  updateQuote,
  getAllUnderwriters,
  requestPayment,
  getQuote,
  startQuote,
} from "../../../services/quote";
import filter from "lodash/filter";

export const setCarDetailsValues = (payload) => ({
  type: "SET_CAR_DETAILS_VALUES",
  payload,
});

export const setAdditionalValues = (payload) => ({
  type: "SET_ADDITIONAL_VALUES",
  payload,
});

export const selectedVehicle = (payload) => ({
  type: "SELECT_VEHICLE_TYPE",
  payload,
});

export const selectedStep = (payload) => ({
  type: "SELECT_STEP",
  payload,
});

export const selectAddBenefit = (payload) => ({
  type: "SELECT_ADD_BENEFIT",
  payload,
});

export const selectRiskClass = (payload) => ({
  type: "SELECT_RISK_CLASS",
  payload,
});

export const selectBenefitQuote = (payload) => ({
  type: "SELECT_BENEFIT_QUOTE",
  payload,
});

export const selectedAdditionalBenefit = (payload) => ({
  type: "SELECTED_ADDITIONAL_BENEFITS",
  payload,
});

export const flushAddBenefit = () => ({
  type: "FLUSH_ADD_BENEFIT",
});

export const addToCartItem = (payload) => ({
  type: "ADD_TO_CART_ITEM",
  payload,
});

export const setUnderwriterParams = (payload) => ({
  type: "SET_UNDERWRITER_PARAMS",
  payload,
});

export const fetchRiskClasses = (queryParam) => {
  return async (dispatch) => {
    const response = await getRiskClasses(queryParam);
    if (response.success) {
      dispatch({
        type: "FETCH_RISK_CLASSES_SUCCESS",
        payload: response.data,
      });
    } else {
      dispatch(
        addNotification({
          message: response.data.message,
          variant: "error",
        })
      );
    }
  };
};

export const fetchCoverTypes = (selectedRiskClassID) => {
  return async (dispatch) => {
    const response = await getCoverTypes(selectedRiskClassID);
    if (response.success) {
      dispatch({
        type: "FETCH_COVER_TYPES_SUCCESS",
        payload: response.data,
      });
    } else {
      dispatch(
        addNotification({
          message: response.data.message,
          variant: "error",
        })
      );
    }
  };
};

export const fetchVehicleModels = (selectedMakeID) => {
  return async (dispatch) => {
    const response = await getVehicleModels(selectedMakeID);
    if (response.success) {
      dispatch({
        type: "FETCH_VEHICLE_MODELS_SUCCESS",
        payload: response.data,
      });
    } else {
      dispatch(
        addNotification({
          message: response.data.message,
          variant: "error",
        })
      );
    }
  };
};

export const fetchVehicleMakes = () => {
  return async (dispatch) => {
    const response = await getVehicleMakes();
    if (response.success) {
      dispatch({
        type: "FETCH_VEHICLE_MAKES_SUCCESS",
        payload: response.data,
      });
    } else {
      dispatch(
        addNotification({
          message: response.data.message,
          variant: "error",
        })
      );
    }
  };
};

export const fetchVehicleTypes = (categoryName) => {
  return async (dispatch) => {
    const response = await getVehicleTypes(categoryName);
    if (response.success) {
      dispatch({
        type: "FETCH_VEHICLE_TYPES_SUCCESS",
        payload: response.data,
      });
    } else {
      dispatch(
        addNotification({
          message: response.data.message,
          variant: "error",
        })
      );
    }
  };
};

export const fetchTonnageRanges = () => {
  return async (dispatch) => {
    const response = await getTonnageRanges();
    if (response.success) {
      dispatch({
        type: "FETCH_TONNAGE_RANGES_SUCCESS",
        payload: response.data,
      });
    } else {
      dispatch(
        addNotification({
          message: response.data.message,
          variant: "error",
        })
      );
    }
  };
};

export const startGetQuote = (values, moveToStep = 2) => {
  return async (dispatch) => {
    dispatch({
      type: "START_QUOTE_LOADING",
    });
    // dispatch(startSubmit('vehicleForm'));
    const response = await startQuote(values);
    if (response.success) {
      dispatch({
        type: "START_QUOTE_SUCCESS",
        payload: {
          data: response.data,
        },
      });
      dispatch(selectedStep(moveToStep));
    } else {
      dispatch({
        type: "START_QUOTE_FAILURE",
      });
      dispatch(
        addNotification({
          message: response.data.message,
          variant: "error",
        })
      );
    }
    // dispatch(stopSubmit('vehicleForm'));
  };
};

export const createNewQuote = (values) => {
  return async (dispatch) => {
    dispatch({
      type: "FETCH_QUOTES_LOADING",
    });
    dispatch(startSubmit("vehicleForm"));
    const response = await createQuote(values);
    if (response.success) {
      const filterData = filter(response.data, (q) => q.underwriterId);
      const quoteValue = find(response.data, (q) => !q.underwriterId);
      dispatch({
        type: "FETCH_QUOTES_SUCCESS",
        payload: {
          data: response.data,
          quote: quoteValue,
          formValues: values,
        },
      });
      if (values.underwriterId) {
        dispatch(selectBenefitQuote({ show: true, quote: filterData[0] }));
      }
    } else {
      dispatch({
        type: "FETCH_QUOTES_FAILURE",
      });
      dispatch(
        addNotification({
          message: response.data.message,
          variant: "error",
        })
      );
    }
    dispatch(stopSubmit("vehicleForm"));
  };
};

export const updateNewQuote = (values, moveToStep = 4) => {
  return async (dispatch) => {
    dispatch({
      type: "UPDATE_QUOTE_LOADING",
    });
    const response = await updateQuote(values);
    if (response.success) {
      dispatch({
        type: "UPDATE_QUOTE_SUCCESS",
        payload: response.data,
      });
      dispatch(selectedStep(moveToStep));
      dispatch(addToCartItem(values));
    } else {
      dispatch({
        type: "UPDATE_QUOTE_FAILURE",
      });
      dispatch(selectedStep(moveToStep));
      dispatch(addToCartItem(values));
      dispatch(
        addNotification({
          message: response.data.title,
          variant: "error",
        })
      );
    }
  };
};

export const fetchAllUnderwriter = (selectedUnderwriter) => {
  return async (dispatch) => {
    dispatch({
      type: "FETCH_ALL_UNDERWRITER_LOADING",
    });
    const response = await getAllUnderwriters();
    if (response.success) {
      const getSelectedUnderwriter = find(response.data, (value) => {
        return (
          kebabCase(value.name.toLowerCase()).search(
            kebabCase(selectedUnderwriter.toLowerCase())
          ) > -1 ||
          value.name
            .replace(/\s/g, "")
            .toLowerCase()
            .search(selectedUnderwriter.toLowerCase()) > -1
        );
      });
      dispatch({
        type: "FETCH_ALL_UNDERWRITER_SUCCESS",
        payload: getSelectedUnderwriter || {},
      });
    } else {
      dispatch({
        type: "FETCH_ALL_UNDERWRITER_FAILURE",
        payload: [],
      });
    }
  };
};

export const fetchAdditionalBenefits = (selectedQuoteID) => {
  return async (dispatch) => {
    dispatch({
      type: "FETCH_ADDITIONAL_BENEFITS_LOADING",
    });
    const response = await getAdditionalBenefits(selectedQuoteID);
    if (response.success) {
      dispatch({
        type: "FETCH_ADDITIONAL_BENEFITS_SUCCESS",
        payload: response.data,
      });
    } else {
      dispatch({
        type: "FETCH_ADDITIONAL_BENEFITS_FAILURE",
        payload: [],
      });
    }
  };
};

export const makePaymentRequest = (values) => {
  return async (dispatch) => {
    dispatch({
      type: "MAKE_PAYMENT_LOADING",
    });
    const response = await requestPayment(values);
    if (response.success) {
      window.location.href = response.data;
      // dispatch({
      //   type: 'MAKE_PAYMENT_SUCCESS',
      //   payload: response.data,
      // });
    } else {
      dispatch({
        type: "MAKE_PAYMENT_FAILURE",
        payload: {},
      });
      dispatch(
        addNotification({
          message: response.data.title,
          variant: "error",
        })
      );
    }
  };
};

export const getPaymentQuote = (data) => {
  return async (dispatch) => {
    const response = await getQuote(data);
    dispatch(
      setCarDetailsValues({
        mobile: response.data.mobile?.replace(/^0+/, "254"),
      })
    );
    dispatch(addToCartItem(response.data));
    dispatch({
      type: "FETCH_QUOTES_SUCCESS",
      payload: {
        data: response.data,
        formValues: response.data,
      },
    });
    dispatch(fetchCoverTypes(response.data?.riskClassId));
  };
};
