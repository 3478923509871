import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { getPaymentQuote, selectedStep } from "../../../shared/actions/quote";
import { lazy } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getQuote } from "../../../services/quote";
const QuoteContainer = lazy(() => import("../container"));

const PaymentSummary = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    dispatch(selectedStep(6));
    const searchParams = new URLSearchParams(location.search);
    dispatch(getPaymentQuote(params.id));
  }, []);

  return <QuoteContainer {...props} />;
};

PaymentSummary.propTypes = {};

export default PaymentSummary;
