import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    minHeight: "calc(100vh - 59px)",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
  },
  primary: {
    fontWeight: 600,
  },
  secondary: {
    fontWeight: 400,
    marginBottom: theme.spacing(2),
  },
  paragraph: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  button: {
    borderRadius: 20,
    padding: theme.spacing(1, 5),
  },
}));
