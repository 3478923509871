import React, { memo } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import PublicLayout from "./public";

const Layouts = {
  public: PublicLayout,
};

const IndexLayout = ({ children }) => {
  const getLayout = () => {
    return "public";
  };
  const Container = Layouts[getLayout()];
  const BootstrappedLayout = () => {
    return <Container>{children}</Container>;
  };
  return (
    <>
      <Helmet title="Vehicle - Insurance Quote" />
      {BootstrappedLayout()}
    </>
  );
};

IndexLayout.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};

export default memo(IndexLayout);
