import classname from "classnames";
import React from "react";
import PropTypes from "prop-types";
import kebabCase from "lodash/kebabCase";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import { Typography } from "@material-ui/core";
import {
  SanlamIcon,
  ICEAIcon,
  AllianzIcon,
  AmacoIcon,
  AIGIcon,
  APAIcon,
  BritamIcon,
  HeritageIcon,
  FirstAssuranceIcon,
} from "./backgrounds";
import PathImage from "../../../images/path_74.png";
import VehicleImage from "../../../images/group_97.png";
import useStyles from "./styles";

const backgrounds = [
  {
    name: "allianz",
    title: "Allianz Motor Insurance",
    background: <AllianzIcon height="100%" width="100%" />,
  },

  {
    name: "icealion",
    title: "ICEA Motor Insurance",
    background: <ICEAIcon height="100%" width="100%" />,
  },
  {
    name: "amaco",
    title: "Amaco Motor Insurance",
    background: <AmacoIcon height="100%" width="100%" />,
  },
  {
    name: "AIG",
    title: "AIG Motor Insurance",
    background: <AIGIcon height="100%" width="100%" />,
  },
  {
    name: "APA",
    title: "APA Motor Insurance",
    background: <APAIcon height="100%" width="100%" />,
  },
  {
    name: "britam",
    title: "With you every step of the way!",
    background: <BritamIcon height="100%" width="100%" />,
  },
  {
    name: "sanlam",
    title: "Sanlam Motor Insurance",
    background: <SanlamIcon height="100%" width="100%" />,
  },
  {
    name: "heritage",
    title: "Heritage Motor Insurance",
    background: <HeritageIcon height="100%" width="100%" />,
  },
  {
    name: "firstassurance",
    title: "First Assurance Motor Cover",
    background: <FirstAssuranceIcon height="100%" width="100%" />,
  },
];

const DefaultImage = () => {
  const classes = useStyles();
  return (
    <div className={classes.middleContainer}>
      <div className={classes.middleContent}>
        <img className={classes.image} src={PathImage} alt="path-vehicle" />
        <img
          className={classname(classes.image, classes.absolute)}
          src={VehicleImage}
          alt="vehicle-brand"
        />
      </div>
      <div className={classes.textContainer}>
        <Typography
          variant="h4"
          component="h4"
          className={classes.brandPrimary}
        >
          Simple Car Insurance.
        </Typography>
      </div>
    </div>
  );
};

const BackgroundImage = ({ name }) => {
  const classes = useStyles();
  if (isEmpty(name)) return <DefaultImage />;
  const getBackground = find(
    backgrounds,
    (value) =>
      kebabCase(value.name.toLowerCase()).search(
        kebabCase(name.toLowerCase())
      ) > -1
  );
  if (isEmpty(getBackground)) return <DefaultImage />;
  const BootstrappedLayout = () => {
    const { background, title } = getBackground;
    return (
      <div className={classes.middleContainer}>
        <div className={classes.middleContent}>{background}</div>
        <div className={classes.textContainer}>
          <Typography
            variant="h4"
            component="h4"
            className={classes.brandPrimary}
          >
            {title}
          </Typography>
        </div>
      </div>
    );
  };
  return BootstrappedLayout();
};

BackgroundImage.propTypes = {
  name: PropTypes.string,
};
BackgroundImage.defaultProps = {
  name: "",
};
export default BackgroundImage;
