import { makeStyles } from "@material-ui/core";
import { textWhite } from "../../themes/colors";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    display: "flex",
    boxSizing: "border-box",
    flexBasis: "auto",
    alignItems: "stretch",
    flexShrink: 1,
    position: "relative",
    flexDirection: "row",
    minHeight: "100vh",
    minWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  contentContainer: {
    flexGrow: 1,
    flexShrink: 1,
    alignItems: "flex-start",
    flexDirection: "column",
    display: "flex",
    padding: theme.spacing(0),
  },
  content: {
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: "column",
    display: "flex",
    width: "100%",
    paddingLeft: 80,
    paddingRight: theme.spacing(5),
    backgroundColor: textWhite,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: theme.spacing(6),
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  bottomContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    backgroundColor: "#fff",
  },
  bottomContent: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
    height: 59,
    paddingRight: theme.spacing(5),
  },
  bottomText: {
    color: "#707070",
    fontSize: 10,
    fontFamily: "'Quicksand', sans-serif",
  },
}));
