import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import notificationReducer from "../shared/notification/reducer";
import reducers from "../modules/reducers";

const rootReducer = combineReducers({
  form: formReducer,
  notification: notificationReducer,
  quote: reducers.quoteReducer,
});

export default rootReducer;
