import axios from "../config";

export function getCoverTypes() {
  return axios
    .get(`/cover-types?companyId=1`)
    .then((response) => ({
      data: response.data,
      success: true,
    }))
    .catch((error) => ({
      data: (error.response && error.response.data) || [],
      success: false,
    }));
}

export function getRiskClasses(queryParam) {
  return axios
    .get(
      `/risk-classes/all?companyId=1&riskCategoryName=Motor&byClassificationCategory=true&classificationCategory=${
        queryParam || ""
      }`
    )
    .then((response) => ({
      data: response.data,
      success: true,
    }))
    .catch((error) => ({
      data: (error.response && error.response.data) || [],
      success: false,
    }));
}

export function getVehicleTypes(categoryName) {
  return axios
    .get(`/vehicle-types?classificationCategory=${categoryName}`)
    .then((response) => ({
      data: response.data,
      success: true,
    }))
    .catch((error) => ({
      data: (error.response && error.response.data) || [],
      success: false,
    }));
}

export function getVehicleModels(vehicleMakeID) {
  return axios
    .get(`/car-models?carMakeId=${vehicleMakeID}`)
    .then((response) => ({
      data: response.data,
      success: true,
    }))
    .catch((error) => ({
      data: (error.response && error.response.data) || [],
      success: false,
    }));
}

export function getVehicleMakes() {
  return axios
    .get(`/car-makes`)
    .then((response) => ({
      data: response.data,
      success: true,
    }))
    .catch((error) => ({
      data: (error.response && error.response.data) || [],
      success: false,
    }));
}

export function getTonnageRanges() {
  return axios
    .get(`/tonnage-ranges`)
    .then((response) => ({
      data: response.data,
      success: true,
    }))
    .catch((error) => ({
      data: (error.response && error.response.data) || [],
      success: false,
    }));
}

export function getQuote(quoteID) {
  return axios
    .get(`/quotes/${quoteID}`)
    .then((response) => ({
      data: response.data,
      success: true,
    }))
    .catch((error) => ({
      data: (error.response && error.response.data) || [],
      success: false,
    }));
}

export function getAdditionalBenefits(quoteID) {
  return axios
    .get(`/benefits/additional-benefits/${quoteID}?companyId=1`)
    .then((response) => ({
      data: response.data,
      success: true,
    }))
    .catch((error) => ({
      data: (error.response && error.response.data) || [],
      success: false,
    }));
}

export function createQuote(data) {
  return axios
    .post("/quotes", data)
    .then((response) => ({
      data: response.data,
      success: true,
    }))
    .catch((error) => ({
      data: (error.response && error.response.data) || [],
      success: false,
    }));
}

export function getAllUnderwriters() {
  return axios
    .get(`/underwriters?companyId=1`)
    .then((response) => ({
      data: response.data,
      success: true,
    }))
    .catch((error) => ({
      data: (error.response && error.response.data) || [],
      success: false,
    }));
}

export function startQuote(data) {
  return axios
    .post("/quotes/start", data)
    .then((response) => ({
      data: response.data,
      success: true,
    }))
    .catch((error) => ({
      data: (error.response && error.response.data) || [],
      success: false,
    }));
}

export function updateQuote(data) {
  return axios
    .put("/quotes", data)
    .then((response) => ({
      data: response.data,
      success: true,
    }))
    .catch((error) => ({
      data: (error.response && error.response.data) || [],
      success: false,
    }));
}

export function checkPaymentStatus(searchStr) {
  return axios
    .get(`/client-policy-payments/pesapal-payment-status${searchStr}`)
    .then((response) => ({
      data: response.data,
      success: true,
    }))
    .catch((error) => ({
      data: (error.response && error.response.data) || [],
      success: false,
    }));
}

export function requestPayment(data) {
  return axios
    .post(
      `/client-policy-payments/request-payment/pesapal?id=${data.id}&mobile=${data.mobile}`,
      data
    )
    .then((response) => ({
      data: response.data,
      success: true,
    }))
    .catch((error) => ({
      data: (error.response && error.response.data) || [],
      success: false,
    }));
}
