import React from "react";
import classname from "classnames";
import { useSelector } from "react-redux";
import { Typography, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import BrandLogo from "./brand-logo";
import MiddleBackground from "../../../assets/svgs/underwriters/backgrounds";
import BottomBackground from "./bottom-background";
import { AppIcon } from "../../../assets/svgs";
import useStyles from "./styles";

const LeftSidebar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const underwriterName = useSelector((state) => state.quote.underwriterName);

  return (
    <div className={classes.container}>
      {mobileScreen && <BrandLogo name={underwriterName} />}
      {!mobileScreen && (
        <div className={classes.positionContainer}>
          <div className={classes.contentContainer}>
            <BrandLogo name={underwriterName} />
            <MiddleBackground name={underwriterName} />
            <BottomBackground />
          </div>
          <div className={classes.bottomContentContainer}>
            <div className={classes.bottomContent}>
              <Typography className={classes.primaryText}>
                POWERED BY
              </Typography>
              <AppIcon className={classes.bottomBrandIcon} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

LeftSidebar.propTypes = {};

export default LeftSidebar;
