/* eslint-disable no-param-reassign */
import axios from "axios";
import { BASE_URL } from "./constants";

axios.defaults.baseURL = `${BASE_URL}`;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common.Accept = "application/json";

axios.interceptors.request.use((config) => {
  // const AUTH_TOKEN = localStorage.getItem("AUTH_TOKEN");
  // config.headers.Authorization = AUTH_TOKEN ? `Bearer ${AUTH_TOKEN}` : "";
  return config;
});

export default axios;
