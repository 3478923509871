export default {
  palette: {
    primary: {
      main: "#149FCB",
    },
    secondary: {
      main: "#14CB8C",
    },
    success: {
      main: "#14CB8C",
    },
    background: {
      default: "#FFF",
      light: "#FFF",
    },
    error: {
      main: "#FF0707",
    },
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100,
    modal: 1300,
  },
};
